.presentation-form {
    .description-wrapper {
        position: relative;
    }

    .translate-button {
        position: absolute;
        right: 25px;
        bottom: 15px;
        box-shadow: 2px 3px 5px gray;
    }
}
