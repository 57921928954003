@import "/src/style/AgathaLabs";

.media-content-list-entry.box {
    border: 1px solid $primary;

    .media-left {
        width: 228px;
    }

    .content {
        display: -webkit-box;
        max-width: 326px;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space: break-spaces;
        word-break: break-word;
    }
}
