html,body {
    height:100%;
}

.is-fullheight {
    height:100%
}

.column.is-fullheight {
    height: 100vh;
}

// making the background of notifications (react-notifications-component) transparent
// instead of the default whitesmoke
.notifications-component .notification {
    background-color: transparent !important;
}

.button.is-circular {
    border-radius: 50% !important;
}

.table.is-vcentered td, .table.is-vcentered th {
    vertical-align: middle;
}

.table.has-text-centered {
    th {
        text-align: center !important;
    }
}

.filters.dropdown-menu {
    width: 300px;
}

// missing translation
.icon.is-danger {
    background-color: red;
    border-radius: 50%;
    color: white;
}

// fix image overflow when the image is not a square
.is-square.no-padding-top {
    padding-top: 0 !important;
}
