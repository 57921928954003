@import "/src/style/AgathaLabs";

.login-form {
    min-width: 450px;
}

.login-form .logo {
    height: 80px;
}

.login-form .show-password-icon {
    &:hover svg {
        color: $primary;
    }

    .is-password-visible svg {
        color: $primary;
    }
}
