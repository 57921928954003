@charset "utf-8";

@import url("https://pro.fontawesome.com/releases/v5.10.0/css/all.css"); //If you want font awesome

@import "~bulma/sass/utilities/functions";

// Custom theme colors
$primary: #6c0daf; // this variable name already exists in bulma so no need to create the inverted ligth and dark versions of it, nor add it to the custom colors variable
$secondary: hsl(171, 100%, 31%) !default;

$secondary-invert: findColorInvert($secondary);
$secondary-light: findLightColor($secondary);
$secondary-dark: findDarkColor($secondary);

$custom-colors: (
    "secondary": (
        // order matters
        $secondary,
        $secondary-invert,
        $secondary-light,
        $secondary-dark,
    ),
);

// Colors have to be imported after you add your color changes.
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/derived-variables";
// From here, the colors can be used however you want.

$menu-item-active-background-color: $primary;
$navbar-dropdown-border-top: 2px solid $primary; // this guy: https://prnt.sc/1l11g4n, if you want to change the color of that border, I prefer it on $primary rather than the default which is grey.
$pagination-current-background-color: $primary; //https://prnt.sc/1l12oxn this guy

$shadow: 0 0.25em 1em 0em rgba($scheme-invert, 0.1),
    0 0px 0 1px rgba($scheme-invert, 0.02);

// This should be always at the end
@import "~bulma/bulma";
@import "~bulma-slider/dist/css/bulma-slider.min.css";
