@import "style/index";

.cookie-consent-bar {
    .content {
        color: white;
        text-align: center !important;
    }

    .cookie-consent-bar-container {
        border-top: 1px solid white !important;
        background-color: $primary !important;
    }
}
