@import "src/style/AgathaLabs";

// file upload
.file-remove {
    border-radius: 0 4px 4px 0 !important;
    cursor: pointer;

    &:hover {
        background-color: #eeeeee !important;
        border-color: #dbdbdb;
    }
}

.has-file-remove {
    border-radius: 0 !important;
}
