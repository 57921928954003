@import "../../../style/AgathaLabs";

.navbarWrapper {
    width: 350px;
    position: sticky;
    top: 0;

    @media only screen and (min-width: 1024px) {
        height: 100vh;
    }
}

.navbarArea {
    box-shadow: $shadow;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.navbarLogoArea {
    height: 200px;
}

.navbarLogoArea img {
    width: 80%;
    max-width: 280px;
    margin: 30px 0px 0px 8%;
}

.navbarLogoArea h5 {
    margin: 0 0 0 8% !important;
    font-size: 20px;
}

.navbarLogoArea h6 {
    margin: 0 0 0 8% !important;
    font-size: 16px;
}

.navbarLogoArea .menu-toggle {
    position: absolute;
    right: .5rem;
    top: .5rem;
}

.collapsed-navbar {
    position: fixed;
    left: 1rem;
    top: 1rem;
}

.navbarLinksArea{
    height: fit-content;
    z-index: 10;
    background-color: #853db8;
    flex-grow: 1;
}
// navbarStyling
.navbarLinksArea {
    padding: 10px 0 0 10px;

    .menu-label {
        color: white;
    }

    .menu-list li {
        font-size: 20px;
    }

    .menu a {
        color: #ffffff;
    }
}

.hamburgerMenuIcon{
    display: none;
}

@media screen and (max-width: 1023px) {
    .navbarArea {
        background-color: #FFF;
        width: 100vw;
        //min-width: 300px;
        position: relative;
        height: auto;
    }
    .navbarLogoArea {
        background-color: #FFF;
        max-width: 100vw;
    }
    .navbarLinksArea {
        padding-top: 0px;
        background-color: #853db8;
        height: auto;
        box-shadow: 0 5px 5px darkgrey;
    }

    .navbarLogoArea h5 {
        margin: 0 0 0 8% !important;
        font-size: 20px;
        max-width: 80%;
    }

    .navbarLogoArea h6 {
        margin: 0 0 0 8% !important;
        font-size: 16px;
        max-width: 80%;
    }

    .hamburgerMenuIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0 10px 0;
    }
    .navbarLinksVisible{
        display: block;
        width: 100%;
    }
    .navbarLinksHidden{
        display: none;
    }
}
